import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTrackEvent } from "../../../../hooks/useTrackEvent";
import { toast } from "react-toastify";
import Toast from "../../Toast";
import {
    useDeleteUserPropertyMutation,
    useGetUserQuery,
    useSaveUserPropertyMutation,
} from "../../../../services/userEndpoints";
import { useDebounce } from "../../../../hooks/useDebounce";

export const useAuthedUser = ({
    listingId,
    location,
    shouldSaveProperty,
    setShouldSaveProperty,
    debounceDelay = 300,
}) => {
    const [isSaved, setIsSaved] = useState(false);
    const userId = useSelector((state) => state.root.userSession.userID);
    const [saveProperty, { isError: isSaveError, error }] =
        useSaveUserPropertyMutation();
    const [deleteProperty, { isError: isDeleteError }] =
        useDeleteUserPropertyMutation();
    const { data: userData = {} } = useGetUserQuery({ userId });
    const preventInitialSave = useRef();
    const debouncedInputValue = useDebounce(isSaved, debounceDelay);
    const isError = isSaveError || isDeleteError;
    const toastId = listingId;
    const { trackGTM } = useTrackEvent();

    const handleSaveOrDelete = () => {
        const action = isSaved ? saveProperty : deleteProperty;
        action({ userId, listingId });
        trackClick();
        displayToast(isSaved, listingId);
    };

    // This will set the initial isSaved state
    useEffect(() => {
        if (Array.isArray(userData?.savedProperties)) {
            const globalIsSaved = userData.savedProperties.includes(listingId);
            if (globalIsSaved !== isSaved) {
                setIsSaved(globalIsSaved);
            }
        }
    }, [userData, listingId]);

    // Debouncing the save action. This prevents a spam click and will only save once
    // The user has stopped clicking for 300ms
    useEffect(() => {
        if (preventInitialSave.current) {
            const globalIsSaved = userData.savedProperties.includes(listingId);
            if (globalIsSaved !== debouncedInputValue) {
                handleSaveOrDelete();
            }
        }
        preventInitialSave.current = true;
    }, [debouncedInputValue]);

    // Handle an error reponse from a save request
    useEffect(() => {
        if (isError) {
            const isLimitExceeded =
                error?.data?.message?.error?.includes("limit");
            const content = isLimitExceeded
                ? "We are unable to save this property. Please remove properties from your Saved Properties to continue."
                : "Sorry, We we ran into an issue. Please try again later.";
            const toastData = {
                render: <Toast content={content} />,
                type: isLimitExceeded ? toast.TYPE.WARNING : toast.TYPE.ERROR,
                icon: false,
                autoClose: 10000,
            };

            if (toast.isActive(toastId)) {
                toast.update(toastId, toastData);
            } else {
                toast(<Toast content={content} />, toastData);
            }
        }
    }, [error, isError]);

    // This effect waits till the user is logged in to attempt the save again.
    useEffect(() => {
        if (shouldSaveProperty && Array.isArray(userData?.savedProperties)) {
            setIsSaved(!isSaved);
            setShouldSaveProperty(false);
        }
    }, [shouldSaveProperty, userData]);

    /**
     * Tracks the click event in Google Analytics.
     */
    const trackClick = () => {
        trackGTM({
            event: `buttonClick`,
            action: "click",
            type: "Save Property",
            category: "user_action",
            value: isSaved ? "delete" : "save",
            location,
            listingId,
        });
    };

    /**
     * Displays a toast message indicating if a property was saved or removed.
     * @param {boolean} isSaved - A boolean indicating whether the property was saved or removed.
     * @param {string} toastId - The ID of the toast message.
     */
    const displayToast = () => {
        const toastContent = !isSaved
            ? "Property Saved!"
            : "Saved Property Removed.";
        if (!toast.isActive(toastId)) {
            toast.success(<Toast content={toastContent} />, {
                icon: false,
                toastId,
                autoClose: 2000,
            });
        } else {
            toast.update(toastId, {
                render: <Toast content={toastContent} />,
                type: "success",
                isLoading: false,
                autoClose: 2000,
            });
        }
    };

    const handleClick = () => {
        setIsSaved(!isSaved);
    };

    return {
        isSaved,
        handleClick,
    };
};
