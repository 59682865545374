import React from "react";
import IndeterminateButton from "../IndeterminateButton";
import { useUserLogout } from "../../hooks/logout";
import { useCookies } from "react-cookie";

/**
 * Logs the user out, then reloads and clears FE caches
 * @param children {React.ReactNode} - The content of the button
 * @param props {object} - Other props to pass to the button
 *
 */
const Logout = ({ children, ...props }) => {
    const [cookie, setCookie, remove] = useCookies(["sessionId"]);
    const { execute, isLoading } = useUserLogout();

    const handleLogout = async () => {
        await execute(); // logs the user out in the DB
        remove(); // removes the cookie (this will be reset on load)
        location.reload(); // reload the page
    };

    return (
        <IndeterminateButton
            {...props}
            isLoading={isLoading}
            onClick={async () => await handleLogout()}
        >
            {children}
        </IndeterminateButton>
    );
};

export default Logout;
