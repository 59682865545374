import React, { useState } from "react";
import { useSelector } from "react-redux";
import AnonUser from "./AnonUser";
import AuthedUser from "./AuthedUser";

/**
 * This component will render a heart button that can be used to save a property.
 * When clicked, it will either add the property to the user's saved properties or remove it.
 * If the user is not logged in, the button will first open the login drawer.
 *
 * @param listingId {string} - The ID of the property to be saved
 * @param location {string} - The location of the property to be saved
 * @param props {object} - Other props to pass to the button
 * @returns {React.ReactElement}
 * @example
 * <SaveProperty listingId="123456">
 *   Save Property
 * </SaveProperty>
 */
const SaveProperty = ({ listingId, location, ...props }) => {
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const [shouldSaveProperty, setShouldSaveProperty] = useState(false);

    return (
        <>
            {isLoggedIn ? (
                <AuthedUser
                    {...props}
                    listingId={listingId}
                    location={location}
                    shouldSaveProperty={shouldSaveProperty}
                    setShouldSaveProperty={setShouldSaveProperty}
                />
            ) : (
                <AnonUser
                    {...props}
                    setShouldSaveProperty={setShouldSaveProperty}
                />
            )}
        </>
    );
};

export default SaveProperty;
