import React from "react";
import styled from "styled-components";
import { ToastContainer, Slide } from "react-toastify";

function ToastProvider({ children }) {
    return (
        <>
            {children}
            <StyledToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
                transition={Slide}
                limit={2}
            />
        </>
    );
}

const StyledToastContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
        bottom: 0;
        min-height: 50px;
        width: 600px;
    }
    .Toastify__toast {
        border-radius: 6px;
        padding-y: 0;
        min-height: 50px;
    }
    .Toastify__toast-body {
        color: black;
    }
    .Toastify__toast--success {
        border-left: var(--bs-secondary) 7px solid;
    }
    .Toastify__toast--error {
        border-left: var(--bs-danger) 7px solid;
    }
    .Toastify__toast--warning {
        border-left: var(--bs-warning) 7px solid;
    }

    @media only screen and (max-width: 600px) {
        &&&.Toastify__toast-container {
            width: 100%;
        }
    }
`;

export default ToastProvider;
