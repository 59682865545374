import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import PubSub from "pubsub-js";
import Drawer from "../Drawer";
import useToggle from "../../hooks/useToggle";
import { FaBars, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { LoginOrRegister, Logout, Summary } from "../Account";
import LocationSelect from "../Search/LocationSelect";
import Search from "../Search/Search";
import { setMode, setPageError } from "../../reducers/rootReducer";
import NavLink from "./NavLink";
import CdnImage from "../CdnImage";
import { useNacLocation } from "../../hooks/useNacLocation";
import { matchRoute } from "../../routes";

const Header = ({ styling, navItems }) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const nicheSearchBoxText = useSelector(
        (state) => state.root.styling.nicheSearchBoxText
    );
    const firstName = useSelector((state) => state.root.userSession.firstName);
    const { pathname, state: { isPageNotFound } = {} } = useNacLocation();
    const mode = useSelector((state) => state.root.mode);
    const [startingTab, setStartigTab] = useState("login");
    const [drawerContent, setDrawerContent] = useState(null);
    const [showAccount, toggleShowAccount] = useToggle(false);
    const [lakesIsOpen, toggleLakes] = useToggle(false);
    const [loginCallback, setLoginCallback] = useState();
    const { nicheName, nichePhoneNumber, nichetypePlural } = styling || {};
    const isState = matchRoute(pathname) === "state";

    // Making a copy of navItems (its immutable) so i can split
    // the first item (states list ie: Browse Lake Homes)
    const navItemsParts = [...navItems];
    const navStatesList = navItemsParts.shift();

    useEffect(() => {
        const sub = PubSub.subscribe(
            "TOGGLE_MOBILE_DRAWER",
            (msg, callback) => {
                if (callback) setLoginCallback(callback);
                toggleMenu(true);
            }
        );
        return () => PubSub.unsubscribe(sub);
    }, [toggleShowAccount]);

    const toggleMenu = (showAccount = false) => {
        setDrawerContent(drawerContent !== "menu" ? "menu" : null);
        toggleShowAccount(showAccount);
    };

    const handleClick = (e, path) => {
        PubSub.publish("RESET_SEARCH");
        if (mode === "maintenance") {
            dispatch(setPageError());
            dispatch(setMode("normal"));
        }
    };

    const getLabel = (navItem) => navItem.mobileLabel || navItem.label;

    return (
        <>
            <div className="d-flex align-items-center px-3">
                <NavLink className="text-light p-0 py-2" to="/">
                    <CdnImage
                        style={{ height: "40px" }}
                        src={isState ? "/icon-color.png" : "/header-logo.png"}
                        alt={nicheName}
                    />
                </NavLink>
                <div
                    className={`flex-grow-1 px-3 ${
                        mode === "maintenance" || !isState || isPageNotFound
                            ? "d-none"
                            : ""
                    }`}
                    style={{ zIndex: 15 }}
                >
                    <LocationSelect
                        className="w-100"
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 1000,
                                width: "100vw",
                                position: "fixed",
                                left: 0,
                                top: "55px",
                            }),
                        }}
                    />
                </div>
                <div className="d-flex ms-auto" style={{ fontSize: "20px" }}>
                    <div onClick={() => toggleMenu()} data-test="menu">
                        <FaBars className="text-primary" />
                    </div>
                </div>
            </div>
            <Drawer
                isOpen={drawerContent !== null}
                toggle={() => setDrawerContent(null)}
                headerText={firstName}
            >
                <ModalBody>
                    {drawerContent === "menu" && (
                        <>
                            {mode !== "maintenance" && (
                                <>
                                    <Search
                                        callback={() => setDrawerContent(null)}
                                        placeholder={nicheSearchBoxText}
                                        nichetypePlural={nichetypePlural}
                                    />
                                    <div
                                        className={`py-3 ${
                                            !showAccount ? "border-bottom" : ""
                                        }`}
                                    >
                                        <LoginOrRegister
                                            startingTab={startingTab}
                                            showForms={showAccount}
                                            authCallback={(val = true) => {
                                                toggleShowAccount(val);
                                                if (
                                                    typeof loginCallback ===
                                                    "function"
                                                ) {
                                                    loginCallback();
                                                    setLoginCallback();
                                                }
                                            }}
                                        >
                                            <Summary
                                                closeModal={() => toggleMenu()}
                                            />
                                        </LoginOrRegister>
                                    </div>
                                </>
                            )}
                            {!showAccount && (
                                <div className="px-3 mt-2">
                                    <div
                                        onClick={() => toggleLakes()}
                                        data-test="nav-item-find"
                                    >
                                        <div className="d-flex align-items-center py-2 text-start text-primary">
                                            <div className="d-inline-block me-2">
                                                {getLabel(navStatesList)}
                                            </div>
                                            {lakesIsOpen ? (
                                                <FaChevronUp className="ms-auto" />
                                            ) : (
                                                <FaChevronDown className="ms-auto" />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="pl-3"
                                        style={{
                                            maxHeight: lakesIsOpen
                                                ? "2000px"
                                                : "0",
                                            overflow: "hidden ",
                                        }}
                                    >
                                        {Object.values(
                                            navStatesList.children
                                        ).map((childItem, i) => (
                                            <NavLink
                                                key={`dropdown-item-${i}`}
                                                data-test={`dropdown-item`}
                                                className="btn btn-link d-block w-100 my-1 text-start p-0"
                                                to={{
                                                    pathname: childItem.link,
                                                    state: {
                                                        stateId:
                                                            childItem?.stateCode,
                                                    },
                                                }}
                                                onClick={() => {
                                                    handleClick();
                                                    setDrawerContent(null);
                                                }}
                                            >
                                                {getLabel(childItem)}
                                            </NavLink>
                                        ))}
                                    </div>
                                    {navItemsParts.map((item, i) => (
                                        <div key={`nav-item-${i}`}>
                                            {item.children ? (
                                                <>
                                                    <div className="border-bottom my-3"></div>
                                                    {item.children.map(
                                                        (childItem, ci) => (
                                                            <NavLink
                                                                key={`nav-item-child-${ci}`}
                                                                className="d-block px-0 py-2 text-start"
                                                                to={
                                                                    childItem.link
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleClick(
                                                                        e,
                                                                        childItem.link
                                                                    );
                                                                    toggleMenu();
                                                                }}
                                                            >
                                                                {getLabel(
                                                                    childItem
                                                                )}
                                                            </NavLink>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <NavLink
                                                    className="d-block px-0 py-2 text-start"
                                                    to={item.link}
                                                    onClick={(e) => {
                                                        handleClick(
                                                            e,
                                                            item.link
                                                        );
                                                        toggleMenu();
                                                    }}
                                                    data-test={`nav-item-${getLabel(
                                                        item
                                                    )
                                                        .replace(/ /g, "-")
                                                        .toLowerCase()}`}
                                                >
                                                    {getLabel(item)}
                                                </NavLink>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="p-0"
                        color="link"
                        href={`tel:${nichePhoneNumber}`}
                    >
                        {nichePhoneNumber}
                    </Button>
                    {isLoggedIn ? (
                        <Logout
                            className="p-0 ms-auto"
                            color="link"
                            closeModal={() => toggleMenu()}
                        >
                            Sign Out
                        </Logout>
                    ) : (
                        <div className="ms-auto">
                            {mode !== "maintenance" && (
                                <Button
                                    className="p-0"
                                    color="link"
                                    onClick={() => {
                                        setStartigTab("login");
                                        toggleShowAccount();
                                    }}
                                >
                                    Sign in
                                </Button>
                            )}
                        </div>
                    )}
                </ModalFooter>
            </Drawer>
        </>
    );
};

export default Header;
